import React from 'react';
import styled, { css } from 'styled-components/macro';

import { type Calendar_OfferingFieldsFragment } from '../graphql';
import CalendarIconButton from './CalendarIconButton';

const StyledCalendarIconButton = styled(CalendarIconButton)`
  ${({ solid }) =>
    solid &&
    css`
      transform: rotate(30deg);
    `}
  &:hover {
    ${({ solid }) =>
      !solid &&
      css`
        transform: rotate(30deg);
      `}
  }
`;

type Props = {
  offering: Calendar_OfferingFieldsFragment;
  onClick: (offering: Calendar_OfferingFieldsFragment) => void;
};

const FollowOfferingButton: React.FC<Props> = ({ onClick, offering }) => {
  const isUserFollowing = offering.userOfferings?.some(({ isFollowing }) => !!isFollowing);

  return (
    <StyledCalendarIconButton
      onClick={() => onClick(offering)}
      iconName="thumbtack"
      solid={!!isUserFollowing}
      popoverContent={isUserFollowing ? 'Unfollow' : 'Follow'}
    />
  );
};

export default FollowOfferingButton;
