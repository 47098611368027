import { datalabApi } from '@cmg/api';
import { apiUtil } from '@cmg/common';
import { addWeeks, addYears, previousSaturday, subWeeks, subYears } from 'date-fns';
import saveAs from 'file-saver';
import uniq from 'lodash/uniq';

import {
  type OfferingFilterInput,
  OfferingSortInput,
  OfferingStatus,
} from '../../../graphql/__generated__/index';
import { type NestedSortInput } from '../../../graphql/types';
import { CalendarCategory } from '../../../types/domain/calendar/constants';
import { getGraphqlWhere } from '../hooks/useCalendarQuery.model';
import { tabConfig as filedTabConfig } from '../tabs/FiledOfferingsCalendar';
import { tabConfig as liveTabConfig } from '../tabs/LiveOfferingsCalendar';
import { tabConfig as lockupTabConfig } from '../tabs/LockupExpirationsOfferingsCalendar';
import { tabConfig as myOfferingsTabConfig } from '../tabs/MyOfferingsCalendar';
import { tabConfig as myOfferingsWithAllocationTabConfig } from '../tabs/MyOfferingsWithAllocationsCalendar';
import { tabConfig as postponedTabConfig } from '../tabs/PostponedOfferingsCalendar';
import { tabConfig as pricedTabConfig } from '../tabs/PricedOfferingsCalendar';
import { FilterValues } from './calendar-filters';
import { CalendarTabType } from './calendar-tabs';
import { getColumnsConfig } from './offeringListColumns';

type SortModelItem = {
  orderBy: string;
  orderByType: string;
};

export function getSortingModel({ orderBy, orderByType }: SortModelItem): OfferingSortInput {
  const direction = orderByType.toUpperCase();

  return orderBy
    .split('.')
    .reverse()
    .reduce((sort, key, index) => ({ [key]: index === 0 ? direction : sort }), {});
}

// Recursively extract field name into Json object, preserving gql schema structure
export const fieldNameToJson = (acc: Object, input: string[], index: number) => {
  if (index >= input.length) {
    return '';
  }
  const item = input[index];
  acc[item] = fieldNameToJson(acc[item] ?? {}, input, index + 1);
  return acc;
};

// We need to query these fields for calculated fields to work
export const defaultBaseGqlFields = {
  status: '',
  attributes: {
    publicFilingDate: '',
    firstTradeDate: '',
    postponedDate: '',
    pricingDate: '',
    lockUpExpirationDate: '',
  },
};

export const nonGqlFieldIds = [
  'priceRangeLivePricedColumn', // composed from multiple fields
  'sellingRestrictionColumn', // composed from multiple fields
] as string[];

export const gqlFieldIdReplaces = {
  offeringNotes: 'offeringNotes.note',
  ioiNotes: 'ioiNotes.note',
};

export const jsonPathFilterFieldIds = [
  'userOfferings.isFollowing',
  'offeringNotes.note',
  'allocations.id',
  'allocations.id',
  'indicationsOfInterest.id',
  'fundAllocations.id',
  // 'fundIndicationsOfInterest.id', // including this causes timeouts
];

type ColIdName = { colId: string; colName: string };
export const rangeColumns: Record<string, [ColIdName, ColIdName]> = {
  'attributes.latestIpoRangeLowUsd': [
    {
      colId: 'attributes.latestIpoRangeLowUsd',
      colName: 'Price Range Low',
    },
    {
      colId: 'attributes.latestIpoRangeHighUsd',
      colName: 'Price Range High',
    },
  ],
  priceRangeLivePricedColumn: [
    {
      colId: 'attributes.latestIpoRangeLowUsd',
      colName: 'Price Range Low',
    },
    {
      colId: 'attributes.latestIpoRangeHighUsd',
      colName: 'Price Range High',
    },
  ],
  sellingRestrictionColumn: [
    {
      colId: 'attributes.isRule144A',
      colName: 'Rule 144a',
    },
    {
      colId: 'attributes.isRegS',
      colName: 'Reg S',
    },
  ],
};

export const additionalOptions: Record<string, { colName?: string; numberPrecision?: number }> = {
  // 'attributes.pricingDate': { colName: 'Pricing Date' },
  'attributes.exchangeRegionDisplayName': { colName: 'Region' },
  'attributes.exchangeCountryDisplayName': { colName: 'Country Display Name' },
  'attributes.latestGrossProceedsTotalUsd': { colName: 'Size ($)' },
  'attributes.marketCapAtPricingUsd': { colName: 'Market Cap ($)' },
  'attributes.latestOfferPriceUsd:': { colName: 'Offer Price ($)' },
};

const addedColumnsAfter: Record<
  string,
  { colId: string; colName: string; tabs?: CalendarCategory[] }[]
> = {
  'attributes.typeDisplayName': [
    {
      colId: 'attributes.securityTypeDisplayName',
      colName: 'SecurityType',
    },
  ],
  'attributes.exchangeRegionDisplayName': [
    {
      colId: 'attributes.exchangeCountry',
      colName: 'Country Code',
    },
  ],
  'attributes.exchangeCountryDisplayName': [
    {
      colId: 'attributes.pricingCurrency',
      colName: 'Currency',
    },
    {
      colId: 'attributes.latestOfferPrice',
      colName: 'Offer Price',
      tabs: [CalendarCategory.LIVE],
    },
  ],
};

const extraOptionalColumns: Record<
  string,
  {
    colId: string;
    columnName: string;
    numberPrecision?: number;
    operation?: { type: string; config: {} };
  }
> = {
  offeringNotes: {
    colId: 'offeringNotes',
    columnName: 'Offering Notes',
    operation: {
      type: 'Delimit',
      config: {
        filter: '$[*].note',
        delimiter: '; ',
      },
    },
  },
  ioiNotes: {
    colId: 'ioiNotes',
    columnName: 'IOI Notes',
    operation: {
      type: 'Delimit',
      config: {
        filter: '$[*].note',
        delimiter: '; ',
      },
    },
  },
};

const tabList = [
  CalendarCategory.LIVE,
  CalendarCategory.PRICED,
  CalendarCategory.FILED,
  CalendarCategory.POSTPONED,
  CalendarCategory.LOCK_UP_EXPIRATION,
  CalendarCategory.MY_OFFERINGS,
  CalendarCategory.MY_OFFERINGS_WITH_ALLOCATIONS,
];

type ColDef = { field: string; label: string };

const getTabColumns = (
  {
    columnsConfig,
    calendarCategory,
  }: {
    columnsConfig: { field: string; label: string }[];
    calendarCategory: CalendarCategory;
  },
  filters: FilterValues
): ColDef[] =>
  getColumnsConfig(columnsConfig, calendarCategory, filters).map(({ field, label }) => ({
    field,
    label,
  }));

const getSheetConfig = ({
  filters,
}: {
  filters: FilterValues;
}): Record<string, { sheetName: string; filter?: string; columns: ColDef[] }> => {
  const now = new Date();
  const ago20y = subYears(now, 20).toISOString().split('T')[0];
  const ago2w = subWeeks(now, 2).toISOString().split('T')[0];
  const future1y = addYears(now, 1).toISOString().split('T')[0];
  const future20y = addYears(now, 20).toISOString().split('T')[0];
  const future2w = addWeeks(now, 2).toISOString().split('T')[0];
  const yearStart = `${now.getUTCFullYear()}-01-01`;
  const lastSaturday = previousSaturday(now).toISOString().split('T')[0];

  return {
    [CalendarCategory.LIVE]: {
      sheetName: 'Live',
      filter: `$[?(@.status == '${OfferingStatus.Live}' && @.attributes.publicFilingDate >= '${ago20y}' && @.attributes.publicFilingDate <= '${future1y}')]`,
      columns: getTabColumns(liveTabConfig, filters),
    },
    [CalendarCategory.PRICED]: {
      sheetName: 'Priced',
      filter: `$[?(@.status == '${OfferingStatus.Priced}' && @.attributes.firstTradeDate >= '${ago2w}' && @.attributes.firstTradeDate <= '${future1y}')]`,
      columns: getTabColumns(pricedTabConfig, filters),
    },
    [CalendarCategory.FILED]: {
      sheetName: 'Filed',
      filter: `$[?(@.status == '${OfferingStatus.Filed}' && @.attributes.publicFilingDate >= '${ago20y}' && @.attributes.publicFilingDate <= '${future1y}')]`,
      columns: getTabColumns(filedTabConfig, filters),
    },
    [CalendarCategory.POSTPONED]: {
      sheetName: 'Postponed',
      filter: `$[?((@.status == '${OfferingStatus.Postponed}' || @.status == '${OfferingStatus.Withdrawn}') && @.attributes.publicFilingDate >= '${ago20y}' && @.attributes.publicFilingDate <= '${future20y}' && @.attributes.postponedDate >= '${yearStart}' && @.attributes.postponedDate <= '${future20y}')]`,
      columns: getTabColumns(postponedTabConfig, filters),
    },
    [CalendarCategory.LOCK_UP_EXPIRATION]: {
      sheetName: 'Lockup',
      filter: `$[?(@.status == '${OfferingStatus.Priced}' && @.attributes.pricingDate >= '${ago20y}' && @.attributes.pricingDate <= '${future1y}' && @.attributes.lockUpExpirationDate >= '${lastSaturday}' && @.attributes.lockUpExpirationDate <= '${future2w}')]`,
      columns: getTabColumns(lockupTabConfig, filters),
    },
    [CalendarCategory.MY_OFFERINGS]: {
      sheetName: 'My_Offerings',
      // TBD: to be confirmed yet (from both business logic and technical sides)
      filter: `$[?((@.status == '${OfferingStatus.Live}' || @.status == '${OfferingStatus.Filed}' || @.status == '${OfferingStatus.Priced}') && @.attributes.publicFilingDate >= '${ago20y}' && @.attributes.publicFilingDate <= '${future1y}' && (@.userOfferings.isFollowing != true || @.offeringNotes != '' || @.allocations != '' || @.indicationsOfInterest != '' || @.fundAllocations != ''))]`,
      // TBD: the filter should have yet `|| @.fundIndicationsOfInterest != ''` but it's causing timeouts
      columns: getTabColumns(myOfferingsTabConfig, filters),
    },
    [CalendarCategory.MY_OFFERINGS_WITH_ALLOCATIONS]: {
      sheetName: 'My_Offerings',
      // TBD: to be confirmed yet (from both business logic and technical sides)
      filter: `$[?(((@.status == '${OfferingStatus.Live}' || @.status == '${OfferingStatus.Filed}') && @.attributes.publicFilingDate >= '${ago20y}' && @.attributes.publicFilingDate <= '${future1y}') || (@.status == '${OfferingStatus.Priced}' && @.attributes.publicFilingDate >= '${ago2w}' && @.attributes.publicFilingDate <= '${future1y}') && (@.userOfferings.isFollowing != true || @.offeringNotes != '' || @.allocations != '' || @.indicationsOfInterest != '' || @.fundAllocations != ''))]`,
      // TBD: the filter should have yet `|| @.fundIndicationsOfInterest != ''` but it's causing timeouts
      columns: getTabColumns(myOfferingsWithAllocationTabConfig, filters),
    },
  };
};

type GetColumnOptionsParams = {
  columns: ColDef[];
  tabId: CalendarCategory;
  extraColumns: (typeof extraOptionalColumns)[string][];
};

export const getColumnOptions = ({ columns, tabId, extraColumns }: GetColumnOptionsParams) => {
  let index = 0;

  const columnOptions = columns.reduce((acc, item) => {
    const colId = item.field;
    const colName = item.label;

    // add multiple columns to excel if it is a (low - high) range
    if (rangeColumns[colId]) {
      rangeColumns[colId].forEach(rangeItem => {
        const rangeColId = rangeItem.colId.replaceAll('_', '.');
        acc[rangeColId] = {
          columnName: rangeItem.colName,
          displayOrder: index++,
          ...additionalOptions[rangeItem.colId],
        };
      });
    } else {
      acc[colId.replaceAll('_', '.')] = {
        columnName: colName,
        displayOrder: index++,
        ...additionalOptions[colId],
      };
    }

    if (addedColumnsAfter[colId]) {
      const addedColumns = addedColumnsAfter[colId];
      addedColumns.forEach(addedColumn => {
        if (!addedColumn.tabs || addedColumn.tabs.includes(tabId)) {
          acc[addedColumn.colId] = {
            columnName: addedColumn.colName,
            displayOrder: index++,
            ...additionalOptions[addedColumn.colId],
          };
        }
      });
    }

    return acc;
  }, {});

  if (extraColumns.length > 0) {
    extraColumns.forEach(({ colId, ...extraColumn }) => {
      columnOptions[colId] = {
        ...extraColumn,
        displayOrder: index++,
      };
    });
  }

  return columnOptions;
};

type GetExcelDownloadSheetSetupArgs = {
  tabs: CalendarTabType[];
  filters: FilterValues;
  includeOfferingNotes?: boolean;
  includeIoiNotes?: boolean;
  // screen: string | undefined;
  // columns: ColDef[] | undefined;
};

type SheetDef = {
  sheetName: string;
  filter?: string;
  columnOptions: Record<string, { columnName: string; displayOrder: number }>;
};

/**
 * TBD: decide whether we want to respect selected (visible) columns
 *
 * It showed up hiding some columns (yet with different behaviour on active
 * vs inactive tabs) causes confusion, and some columns are required for
 * displaying some other columns (so we would need to make sure these are
 * always visible)
 *
 * The commented-out code belongs to this deprecated column hiding behaviour:
 * Since we need selected (visible) columns for all the sheets but have
 * only these for the active tab, we need to get the default setup of the
 * inactive tabs and merge it with actual setup of the active tab
 */
export const getExcelDownloadSheetSetup = ({
  tabs,
  includeOfferingNotes,
  includeIoiNotes,
  // screen,
  // columns,
  filters,
}: GetExcelDownloadSheetSetupArgs): SheetDef[] => {
  const sheetConfig = getSheetConfig({ filters });
  const availableTabList = tabList.filter(
    tabId => !!tabs.find(tab => tab.value === tabId && !!tab.isAvailable)
  );

  const extraColumns: (typeof extraOptionalColumns)[string][] = [];
  if (includeOfferingNotes) {
    extraColumns.push(extraOptionalColumns.offeringNotes);
  }
  if (includeIoiNotes) {
    extraColumns.push(extraOptionalColumns.ioiNotes);
  }

  return availableTabList.map(tabId => ({
    sheetName: sheetConfig[tabId].sheetName,
    filter: sheetConfig[tabId].filter,
    columnOptions: getColumnOptions({
      // tabId === screen && columns ? columns : sheetConfig[tabId].columns,
      columns: sheetConfig[tabId].columns,
      tabId,
      extraColumns,
    }),
  }));
};

type GetExcelDownloadArgs = {
  gqlFilterInput?: OfferingFilterInput;
  sheetSetup: SheetDef[];
  sortModel?: SortModelItem[];
  defaultSortModel?: NestedSortInput;
  baseGqlFields?: Object;
  includeFundIoi?: boolean;
};

/**
 * Turn selected fields, filter, and sort model into excel download args
 *
 * Based on `dlgw/components/offerings-report-table` modified for the calendar
 * - defines `sheets` (each calendar tab is exported as a sheet)
 * - expects VirtualizedTable columns (instead of AgGrid)
 */
export const getExcelDownloadArgs = ({
  gqlFilterInput,
  sortModel = [],
  defaultSortModel = {},
  sheetSetup,
  baseGqlFields = defaultBaseGqlFields,
  includeFundIoi,
}: GetExcelDownloadArgs): datalabApi.CalendarOfferingsRequestDto => {
  const allColumnKeys = uniq(
    sheetSetup.map(({ columnOptions }) => Object.keys(columnOptions)).flat()
  )
    .map(columnKey => gqlFieldIdReplaces[columnKey] ?? columnKey)
    .concat(jsonPathFilterFieldIds);

  // Recursively turn selected fields into json object, following gql schema structure
  const selectionJson = allColumnKeys.reduce((acc, colId) => {
    const fieldId = colId.replaceAll('.', '_');
    return nonGqlFieldIds.includes(fieldId) ? acc : fieldNameToJson(acc, colId.split('.'), 0);
  }, baseGqlFields);

  // Stringify json object and replace non-gql characters
  const selectionString = JSON.stringify(selectionJson)
    .replaceAll(/"|'|:/gi, '') // remove single ('), double quotes ("), and colon (:)
    .replaceAll(/,/gi, ' '); // replace comma (,) with space
  const selection = selectionString
    .substring(1, selectionString.length - 1) // remove first and last braces ({})
    .replaceAll(/{|}/gi, ' $& '); // add space around braces ({})

  const downloadArg = {
    selection,
    arguments: {
      order: [
        sortModel.length > 0 && sortModel[0] ? getSortingModel(sortModel[0]) : defaultSortModel,
      ],
      where: gqlFilterInput ?? {},
    },
    sheets: sheetSetup,
    includeFundIois: includeFundIoi,
  };

  return downloadArg;
};

export type DownloadPayload = {
  tabs: CalendarTabType[];
  order?: { orderBy: string; orderByType: 'asc' | 'desc' | 'descWithNullFirst' };
  filters: FilterValues;
  includeOfferingNotes?: boolean;
  includeIoiNotes?: boolean;
  includeFundIoi?: boolean;
  screen?: string;
  columns?: ColDef[]; // TODO: unset the optional flag after the tests are updated
};

// VirtualizedTableWidget.downloadExport params
export type DownloadExportProps = Omit<DownloadPayload, 'order' | 'filter'>;

export const downloadCalendarExport = async ({
  tabs,
  order,
  includeOfferingNotes,
  includeIoiNotes,
  includeFundIoi,
  // screen,
  // columns,
  filters,
}: DownloadPayload) => {
  const downloadArgs = getExcelDownloadArgs({
    gqlFilterInput: getGraphqlWhere(undefined, filters),
    sortModel: order && [order],
    sheetSetup: getExcelDownloadSheetSetup({
      tabs,
      filters,
      includeOfferingNotes,
      includeIoiNotes,
      // screen, columns
    }),
    includeFundIoi,
  });

  const resp: datalabApi.DownloadCalendarOfferingsResponse =
    await datalabApi.downloadCalendarOfferings(downloadArgs, {});

  if (resp.ok && resp.data) {
    saveAs(
      resp.data,
      apiUtil.getFilenameFromContentDisposition(
        resp.headers['content-disposition'],
        'calendar-download.xlsx'
      )
    );
  } else if (resp.ok && !resp.data) {
    throw new Error('Empty data returned!');
  } else {
    throw new Error('Download failed!');
  }
};
