/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Calendar_FollowOfferingMutationVariables = Types.Exact<{
  input: Types.FollowOfferingInput;
}>;

export type Calendar_FollowOfferingMutation = {
  __typename?: 'Mutation';
  followOffering: { __typename?: 'FollowOfferingPayload'; offeringId?: string | null };
};

export const Calendar_FollowOfferingDocument = gql`
  mutation Calendar_FollowOffering($input: FollowOfferingInput!) {
    followOffering(input: $input) {
      offeringId
    }
  }
`;
export type Calendar_FollowOfferingMutationFn = Apollo.MutationFunction<
  Calendar_FollowOfferingMutation,
  Calendar_FollowOfferingMutationVariables
>;

/**
 * __useCalendar_FollowOfferingMutation__
 *
 * To run a mutation, you first call `useCalendar_FollowOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendar_FollowOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarFollowOfferingMutation, { data, loading, error }] = useCalendar_FollowOfferingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalendar_FollowOfferingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Calendar_FollowOfferingMutation,
    Calendar_FollowOfferingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Calendar_FollowOfferingMutation,
    Calendar_FollowOfferingMutationVariables
  >(Calendar_FollowOfferingDocument, options);
}
export type Calendar_FollowOfferingMutationHookResult = ReturnType<
  typeof useCalendar_FollowOfferingMutation
>;
export type Calendar_FollowOfferingMutationResult =
  Apollo.MutationResult<Calendar_FollowOfferingMutation>;
export type Calendar_FollowOfferingMutationOptions = Apollo.BaseMutationOptions<
  Calendar_FollowOfferingMutation,
  Calendar_FollowOfferingMutationVariables
>;
